import { AuthenticatedTemplate } from "@azure/msal-react";
import {  IStackStyles, IStackTokens,   Stack } from "@fluentui/react";
import { PropsWithChildren } from "react";

import { NavigationBar } from "./NavigationBar";



const stackTokens: IStackTokens = { childrenGap: 15 };
const stackStyles: Partial<IStackStyles> = {
  root: {
   
    margin: '0 auto',
    textAlign: 'center',
    color: '#605e5c',
  },
};
const stackStyleContent: Partial<IStackStyles> = {
    root: {
     width:'100%',
      margin: '0 auto',
      textAlign: 'center',
      color: '#605e5c',
    },
  };

type Props = PropsWithChildren<{}>;


export const PageLayout = (props:Props) => {
    


    return (
        <Stack horizontalAlign="center" verticalAlign="start" verticalFill styles={stackStyles} tokens={stackTokens}>
            <NavigationBar />
        
                <Stack  tokens={stackTokens} verticalAlign="start" styles={stackStyleContent}>
                        {props.children}
                </Stack>
            <AuthenticatedTemplate>
                <footer>
                </footer>
            </AuthenticatedTemplate>
        </Stack>
    );
};