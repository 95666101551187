import { Stack } from '@fluentui/react';
import * as React from 'react';
//import styles from './ViewProperty.module.scss';

export interface IViewPropertyProps {
    text:string;
    value:string;
}

export interface IViewPropertyState {}

export default class ViewProperty extends React.Component<IViewPropertyProps, IViewPropertyState> {

  constructor(props: IViewPropertyProps) {
    super(props);

    this.state = {
      
    };
  }

  public render(): React.ReactElement<IViewPropertyProps> {
    const {text,value} =this.props
    return (
      <Stack horizontal >
            <span className='proplabel'>{text}:</span>
            <Stack horizontal   >
              <div style={{width: '16em'}} dangerouslySetInnerHTML={{__html:!value?'':value}}>
              
              </div>
            </Stack>
          </Stack>
    );
  }
}
