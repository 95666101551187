
import { msalInstance } from "..";
import { loginRequest } from "../authConfig";
import { IFileItem } from "../interfaces/IFileItem";
import { IListItem } from "../interfaces/IListItem";
import { IListItemDetail, IEntityPerson } from "../interfaces/IListItemDetails";
import { IDataService, IRequestFilter } from "./IDataService";



export class DataService implements IDataService {
    
    
    private tokenresponse: any = null;
    private fetchToken=false;

    public async  getFiles(id: number): Promise<IFileItem[]> {
        const result = await this.secureFetch(`GetFiles?ID=${id}`);
        const data = await result.json();
        return data
    }

    public async getFile(id: string): Promise<Blob> {
        const response = await this.secureFetch(`GetFile?ID=${id}`);
        const blob=await response.blob();
        return blob;
    }

    public async getListItems(top: number, skip: number, filter: IRequestFilter): Promise<IListItem[]> {
        const result = await this.secureFetch('GetItems');
        const data = await result.json()
        const editData = data.map((x: any) => {
            if (x.CertificateValidTill !== null && x.CertificateValidTill !== "") {
                x.CertificateValidTill = new Date(x.CertificateValidTill);
            }
            if (x.Registrationdate !== null && x.Registrationdate !== "") {
                x.Registrationdate = new Date(x.Registrationdate);
            }
            return x;
        })
        return editData;

    }
    public async getListItem(id: number): Promise<IListItemDetail> {
        const result = await this.secureFetch(`GetItem?Id=${id}`);
        const data = await result.json()
        if (data.BestApprovalDate && data.BestApprovalDate !== "") {
            data.BestApprovalDate = new Date(data.BestApprovalDate);
        }
        if (data.RealisticApprovalDate && data.RealisticApprovalDate !== "") {
            data.RealisticApprovalDate = new Date(data.RealisticApprovalDate);
        }
        if (data.PlannedEffectiveDateOfSubmission && data.PlannedEffectiveDateOfSubmission !== "") {
            data.PlannedEffectiveDateOfSubmission = new Date(data.PlannedEffectiveDateOfSubmission);
        }
        if (data.PlannedEffectiveDateOfSubmission && data.PlannedEffectiveDateOfSubmission !== "") {
            data.PlannedEffectiveDateOfSubmission = new Date(data.PlannedEffectiveDateOfSubmission);
        }
        return data;
    }

   
    public async getUserInfo(): Promise<IEntityPerson[]> {
        throw new Error("Method not implemented.");
    }

    async getAT(): Promise<string> {
        console.log('getAT');
        if (this.tokenresponse && this.tokenresponse.expiresOn && this.tokenresponse.expiresOn > new DataService()) {
            console.log('return old');
            return this.tokenresponse.accessToken;
        }
        const request = {
            ...loginRequest,
            account: msalInstance.getAllAccounts()[0]
        };
        console.log('acquireTokenSilent');
        this.tokenresponse = await msalInstance.acquireTokenSilent(request)

        return this.tokenresponse.accessToken;

    }
    private async secureFetch(endpoint: string): Promise<any> {
        const token = await this.getAT();
        const headers = new Headers();
        headers.append('Content-Type', 'application/json');
        headers.append('X-Authorization', `Bearer ${token}`);
        const url = `${process.env["REACT_APP_AAD_APP_FUNCTION_SCOPE_URI"]}/api/${endpoint}`;
        return await fetch(url, {
            method: 'GET',
            headers: headers,

        });
    }
}