import { AuthenticatedTemplate, UnauthenticatedTemplate, useMsal } from "@azure/msal-react";
//import { Login } from '@microsoft/mgt-react';
import { ContextualMenu, ContextualMenuItemType, IContextualMenuItem, IPersonaSharedProps, IStackTokens,  Persona,  PersonaSize, Stack } from "@fluentui/react";
import { useCallback, useRef, useState } from "react";
import logo from './../logo.svg';
import { useTranslation } from 'react-i18next'

import { loginRequest } from "../authConfig";

const stackTokens: IStackTokens = { childrenGap: 15 };




export const NavigationBar = () => {

  const { instance } = useMsal();
  const [showPersonMenu, setShowPersonMenu] = useState<boolean>(false);
  const linkRef = useRef(null);
  const { t } = useTranslation();
  const handleLogin = () => {
    instance.loginPopup(loginRequest)
      .catch((error) => console.log(error));
  };
  const user = instance.getAllAccounts()[0]

  const loggedInUser: IPersonaSharedProps = {
    // imageUrl: TestImages.personaFemale,
    imageInitials: user?.name?.split(' ').map(x=> x.substring(0,1)).join(''),
    text: user?.name,
    secondaryText: user?.username,
    
  };
  const onHideContextualMenuPerson = useCallback(() => setShowPersonMenu(false), []);
  const menuItemsPerson: IContextualMenuItem[] = [
    {
      key: 'person',
      text: loggedInUser.text,
      onClick: () => { return false },
    },
    {
      key: 'divider_1',
      itemType: ContextualMenuItemType.Divider,
    },
    {
      key: 'logout',
      text: "" + t('logout'),
      onClick: () => console.log('Logout clicked'),
    },
  ];


  return (
    <Stack horizontal tokens={stackTokens} horizontalAlign="space-between" className="App-header">
      <Stack horizontal >
        <img className="App-logo" src={logo} alt="logo" />
      </Stack>
      <Stack horizontal ></Stack>
      <Stack horizontal >
        {/*} <select value={lang} onChange={handleChange}>
                {languages.map(item => {
                    return (<option key={item.value} 
                    value={item.value}>{item.text}</option>);
                })}
            </select>
              */}
        <UnauthenticatedTemplate>

          <div onClick={handleLogin}>Sign in using Popup</div>
          <div onClick={() => instance.loginRedirect(loginRequest)}>Sign in using Redirect</div>

        </UnauthenticatedTemplate>
        <AuthenticatedTemplate>
          <Persona
            {...loggedInUser}
           
            size={PersonaSize.size32}
            hidePersonaDetails={false}
            onClick={() => { setShowPersonMenu(!showPersonMenu) }}
            ref={linkRef}
          />
          <ContextualMenu
            items={menuItemsPerson}
            hidden={!showPersonMenu}
            target={linkRef}
            onItemClick={onHideContextualMenuPerson}
            onDismiss={onHideContextualMenuPerson}
          />
        </AuthenticatedTemplate>
      </Stack>



    </Stack>


  );
};