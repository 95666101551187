import React, { Suspense } from 'react';
import './App.css';
import "./i18n";

import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import { AuthenticatedTemplate, MsalProvider, UnauthenticatedTemplate, useIsAuthenticated, useMsal } from '@azure/msal-react';
import { PublicClientApplication } from "@azure/msal-browser";
import { PageLayout } from './components/PageLayouts';
import { Overview } from './pages/Overview';
import { loginRequest } from './authConfig';



const Pages = () => {
  return (
    <Switch>
      <Route>
        <Overview />
      </Route>
      <Route path="/function">

      </Route>
    </Switch>
  )
}

type AppProps = {
  instance: PublicClientApplication;
}

export const App = ({ instance }: AppProps) => {
  const isAuthenticated = useIsAuthenticated();
  const {  inProgress } = useMsal();

  if (inProgress === "none" && !isAuthenticated) {
    instance.loginRedirect(loginRequest);
  }
  return (
    <Router>
      <MsalProvider instance={instance}>
        <Suspense fallback="...loading">

          <PageLayout>
            <AuthenticatedTemplate>
              <Pages />
            </AuthenticatedTemplate>

            <UnauthenticatedTemplate>
              <h5 className="card-title">Please sign-in.</h5>
            </UnauthenticatedTemplate>
          

        </PageLayout>
      </Suspense>
    </MsalProvider>
    </Router >

  );
};
