export interface IFileItem {
 Name:string;
 FileId:string;
 Area:FileArea
}

export enum FileArea{
    Unknow=0,
    Labels=1,
    SDS=2
}