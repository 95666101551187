import { IconButton, Stack, TextField } from '@fluentui/react';
import * as React from 'react';
//import styles from './FilterDropdown.module.scss';

export interface IFilterTextProps {
  name: string;
  value: string | null;
  onValueChanged: (value: string | null) => void
}

export interface IFilterTextState { }

export default class FilterText extends React.Component<IFilterTextProps, IFilterTextState> {
  public render(): React.ReactElement<IFilterTextProps> {
    const { name, onValueChanged, value } = this.props;
    return (
      <Stack horizontal >
        <span className='name'>{name}</span>
        <Stack horizontal   >
          <TextField value={value || ''}
            style={{ width: 'calc( 15em - 2px )' }}
            onChange={(e, v) => {
              let nval: string | null = v ? v : null
              if (nval && nval.length === 0) {
                nval = null;
              }
              onValueChanged(nval);
            }} />
          <div style={{ width: '2em' }}>
            {value && <IconButton iconProps={{ iconName: "Cancel" }} onClick={() => { onValueChanged(null); }} />}
          </div>
        </Stack>
      </Stack>
    );
  }
}
