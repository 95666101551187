import { DetailsList, Selection, IColumn, Link, Image, ImageFit, mergeStyles, Text, CheckboxVisibility } from "@fluentui/react";
import React from "react";
import { IListItem } from "../interfaces/IListItem";


export interface IListViewState {
  viewItems?: IListItem[];
  columns: IColumn[];

}

export interface IListViewProps {
  viewItems: any[];
  columns: IColumn[];
  openItemClicked: (Id: number) => void;

}

export class ListView extends React.Component<IListViewProps, IListViewState> {
  private _selection: Selection;
  constructor(props: IListViewProps) {
    super(props);

    this._onColumnClick = this._onColumnClick.bind(this);
    this._selection = new Selection({
      onSelectionChanged: () => {
        let selection = this._selection.getSelection();

        if (selection && selection.length > 0) {
          const selectedItem = selection[0] as any;
          console.log(selectedItem);
          this.props.openItemClicked(selectedItem["ID"]);
          this._selection.setAllSelected(false);
        }
      }
    });
    this.state = {
      viewItems: props.viewItems.slice(),
      columns: props.columns.map(c => {
        c.onColumnClick = this._onColumnClick;
        return c;
      })
    };
  }
  componentDidUpdate(prevProps: Readonly<IListViewProps>, prevState: Readonly<IListViewState>, snapshot?: any): void {
    console.log("ListView componentDidUpdate");
    if (this.props.viewItems !== prevProps.viewItems ||
      this.props.columns !== prevProps.columns) {
      this.setState({
        viewItems: this.props.viewItems,
        columns: this.props.columns.map(c => {
          c.onColumnClick = this._onColumnClick;
          return c;
        }),
      });
    }
  }
  componentDidMount(): void {
    console.log("ListView componentDidMount");
    if (this.props.viewItems && this.props.columns) {
      this.setState({
        viewItems: this.props.viewItems,
        columns: this.props.columns.map(c => {
          c.onColumnClick = this._onColumnClick;
          return c;
        }),
      });
    }
  }
  public render() {
    const { viewItems, columns } = this.state;

    if (!viewItems) {
      return (<Text>No Data</Text>);
    }

    return (
      <DetailsList
        items={viewItems}
        setKey="set"
        columns={columns}
        
        onRenderItemColumn={
          (item?: any, index?: number | undefined, column?: IColumn | undefined) => {
            return _renderItemColumn(item, index, column);
          }
        }
        checkboxVisibility={CheckboxVisibility.hidden}
        selection={this._selection}
        onItemInvoked={this._onItemInvoked.bind(this)}
        onColumnHeaderContextMenu={this._onColumnHeaderContextMenu}
        ariaLabelForSelectionColumn="Toggle selection"
        ariaLabelForSelectAllCheckbox="Toggle selection for all items"
        checkButtonAriaLabel="select row"
      />
    );
  }

  private _onColumnClick = (event: React.MouseEvent<HTMLElement>, column: IColumn): void => {
    const { columns } = this.state;
    let { viewItems } = this.state;
    let isSortedDescending = column.isSortedDescending;
    if (column.isSorted) {
      isSortedDescending = !isSortedDescending;
    }
    if (viewItems)
      viewItems = _copyAndSort(viewItems, column.fieldName!, column, isSortedDescending);
    this.setState({
      viewItems: viewItems,
      columns: columns.map(col => {
        col.isSorted = col.key === column.key;

        if (col.isSorted) {
          col.isSortedDescending = isSortedDescending;
        }

        return col;
      }),
    });
  };



  private _onColumnHeaderContextMenu(column: IColumn | undefined, ev: React.MouseEvent<HTMLElement> | undefined): void {
    console.log(`column ${column!.key} contextmenu opened.`);
  }

  private _onItemInvoked(item: any, index: number | undefined): void {
    console.log(`Item ${item.TradeName} at index ${index} has been invoked.`);
    this.props.openItemClicked(item.Id);
  }


}

export function _renderItemColumn(item?: any, index?: number, column?: IColumn) {
  if (!column || !item)
    return "";
    
  const fieldContent = item[column.fieldName as any] as string;
  console.log(column.name);
  switch (column.key) {
    case 'thumbnail':
      return <Image src={fieldContent} width={50} height={50} imageFit={ImageFit.cover} />;

    case 'name':
      return <Link href="#">{fieldContent}</Link>;

    case 'color':
      return (
        <span
          data-selection-disabled={true}
          className={mergeStyles({ color: fieldContent, height: '100%', display: 'block' })}
        >
          {fieldContent}
        </span>
      );
    case 'Registrationdate':
    case 'CertificateValidTill':
      if (!fieldContent)
        return (<span></span>);
      return (
        <span className="aligndate">
          {new Date(fieldContent).toLocaleDateString("de-DE")}
        </span>
      );
    case 'Comment':
      if (!fieldContent)
        return (<span></span>);
      return (
        <span dangerouslySetInnerHTML={{__html:fieldContent}}/>
      );
    default:
      return <span>{fieldContent}</span>;
  }
}

function _copyAndSort<T>(items: T[], columnKey: string, column: IColumn, isSortedDescending?: boolean): T[] {
  const key = columnKey as keyof T;
  /*
    if (columnKey.indexOf('date') !== -1 || columnKey.indexOf('till') !== -1 ) {
      return items.slice(0).sort((a: T, b: T) => (
        (isSortedDescending ?
           new Date(a[key] as string) < new Date(b[key]as string) :
           new Date(a[key] as string) > new Date(b[key]as string)  ) ?
            1 : -1)
      );
    }*/
  return items.slice(0).sort((a: T, b: T) => ((isSortedDescending ? a[key] < b[key] : a[key] > b[key]) ? 1 : -1));
}
