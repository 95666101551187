import { ComboBox, IComboBox, IComboBoxOption, IconButton, Stack } from '@fluentui/react';

import * as React from 'react';
//import styles from './FilterDropdown.module.scss';

export interface IFilterComboBoxProps {
  name: string;
  values: string[];
  selected: string[] | null;
  
  onValueSelected: (selected: string[] | null) => void
}

export interface IFilterComboBoxState { }

export default class FilterComboBox extends React.Component<IFilterComboBoxProps, IFilterComboBoxState> {
  public render(): React.ReactElement<IFilterComboBoxProps> {
    const { name, values, selected, onValueSelected } = this.props;
    return (
      <Stack horizontal >
        <span className='name'>{name}</span>
        <Stack horizontal   >
          <ComboBox style={{ width: '15em', textAlign: 'left' }}
            selectedKey={selected}
            options={values.map(x => { return ({ key: x, text: x }); })}
            allowFreeform
            multiSelect
            
            onChange={this.onChange.bind(this)} />
          <div style={{ width: '2em' }}>
            {selected &&selected.length>0 &&<IconButton iconProps={{ iconName: "Cancel" }} onClick={() => { onValueSelected(null); }} />}
          </div>
        </Stack>
      </Stack>
    );
  }
 
  private onChange(event: React.FormEvent<IComboBox>, option?: IComboBoxOption, index?: number, value?: string): void {
    let selected = option?.selected;
    const prevSelectedKeys = this.props.selected ? this.props.selected : [];
    if (!option && value) {
      
      const prevOptions = [...prevSelectedKeys, value]
      this.props.onValueSelected(prevOptions);
    }

    if (option) {
      const selectedValues = selected ? [...prevSelectedKeys, option!.key as string] : prevSelectedKeys.filter(k => k !== option!.key);
      this.props.onValueSelected(selectedValues);

    }
  }

}
