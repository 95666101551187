import { IColumn } from '@fluentui/react';
import * as React from 'react';
import { withTranslation } from 'react-i18next';
import { IGapItem } from '../interfaces/IGapItem';
import { ListView } from './ListView';
//import styles from './ViewProperty.module.scss';

export interface IViewGAPTableProps {
    values?: IGapItem[];
    noData: string;
    t: any;
    i18n: any;
}

export interface IViewGAPTableState { 
    cols:IColumn[];
}

class ViewGAPTable extends React.Component<IViewGAPTableProps, IViewGAPTableState> {

    constructor(props: IViewGAPTableProps) {
        super(props);

        this.state = {
            cols:[]
        };
    }
    private _buildOverviewColumns(): IColumn[] {
        const {t}= this.props;
        const columns: IColumn[] = [
          { key: "Crop", name: t("Crop"), fieldName: "Crop", minWidth: 150, maxWidth: 300, isResizable: true, showSortIconWhenUnsorted: true },
          { key: "Target", name: t("Target"), fieldName: "Target", minWidth: 100, maxWidth: 300,  isResizable: true, showSortIconWhenUnsorted: true },
          { key: "ApplRate", name: t("ApplRate"), fieldName: "ApplRate", minWidth: 100, maxWidth: 300, isResizable: true, showSortIconWhenUnsorted: true },
          { key: "ApplTime", name: t("ApplTime"), fieldName: "ApplTime", minWidth: 100, maxWidth: 300, isResizable: true, showSortIconWhenUnsorted: true },
          { key: "WaterVolume", name: t("WaterVolume"), fieldName: "WaterVolume", minWidth: 100, maxWidth: 300, isResizable: true, showSortIconWhenUnsorted: true },
          { key: "SprayInterval", name: t("SprayInterval"), fieldName: "SprayInterval", minWidth: 100, maxWidth: 300, isResizable: true, showSortIconWhenUnsorted: true },
          { key: "PHI", name: t("PHI"), fieldName: "PHI", minWidth: 50, maxWidth: 100, isResizable: true, showSortIconWhenUnsorted: true },
          { key: "BufferZone", name: t("BufferZone"), fieldName: "BufferZone", minWidth: 50, maxWidth: 200, isResizable: true, showSortIconWhenUnsorted: true },
          { key: "FGI", name: t("FGI"), fieldName: "FGI", minWidth: 50, maxWidth: 100, isResizable: true, showSortIconWhenUnsorted: true },
          { key: "NoOfAppl", name: t("NoOfAppl"), fieldName: "NoOfAppl", minWidth: 100, maxWidth: 200, isResizable: true, showSortIconWhenUnsorted: true },
          { key: "Comment", name: t("Comment"), fieldName: "Comment", minWidth: 150, maxWidth: 300, isResizable: true, showSortIconWhenUnsorted: true }
        ]
  
        columns.forEach((column: IColumn) => {
          if (column.name) {
            column.showSortIconWhenUnsorted = true;
          }
        });
  
        return columns;
      }
componentDidMount(): void {
    this.setState({cols:this._buildOverviewColumns()});
}

    public render(): React.ReactElement<IViewGAPTableProps> {
        const { values, noData } = this.props
        const{cols} = this.state;
        console.log(cols.length)
        if (!values || (values && values.length ===0)) {
            return ( <div>
                {noData}
            </div>);
        }
        if(!cols){
            return ( <div>
                Cols not defined
            </div>);
        }
        return (
            
            <ListView viewItems={values} columns={cols} 
            openItemClicked={(id) => {}} />
            
        );
    }
}


export default withTranslation()(ViewGAPTable);