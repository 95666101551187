import {  DefaultButton, FontWeights, getTheme, IButtonStyles, IColumn, IconButton, IIconProps, mergeStyleSets, Modal, Spinner, SpinnerSize, Stack } from '@fluentui/react';
import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { ListView } from '../components/ListView';
import { IListItem } from '../interfaces/IListItem';
import { DataService } from '../services/DataService';
import { IDataService } from '../services/IDataService';
import { useId } from '@fluentui/react-hooks';
import DetailView from '../components/DetailView';
import { useMsal } from '@azure/msal-react';
import FilterDropdown from '../components/FilterDropdown';
import FilterText from '../components/FilterText';
import FilterComboBox from '../components/FilterComboBox';
import {  utils, writeFile } from 'xlsx';

export const Overview = () => {

  const [data, setData] = useState<IListItem[]>([]);
  const [viewData, setViewData] = useState<IListItem[]>([]);
  const [detailViewID, setDetailViewID] = useState<number | null>(null);
  const [detailItem, setDetailItem] = useState<IListItem | null>(null);
  const [columns, setColumns] = useState<IColumn[]>([]);
  const [loading, setLoading] = useState(true);

  const [tradeNameFilter, setTradeNameFilter] = useState<string[] | null>(null);
  const [tradeNameValues, setTradeNameValues] = useState<string[]>([]);

  const [countryStateFilter, setCountryStateFilter] = useState<string[] | null>(null);
  const [countryStateValues, setCountryStateValues] = useState<string[]>([])
  const [statusFilter, setStatusFilter] = useState<string | null>(null);
  const [statusValues, setStatusValues] = useState<string[]>([])
  const [productCategoryFilter, setProductCategoryFilter] = useState<string | null>(null);
  const [productCategoryFilterValues, setProductCategoryValues] = useState<string[]>([])
  const [typeOfSubmissionFilter, setTypeOfSubmissionFilter] = useState<string | null>(null);
  const [typeOfSubmissionValues, setTypeOfSubmissionValues] = useState<string[]>([])

  const [targetValues, setTargetValues] = useState<string[]>([])
  const [targetFilter, setTargetFilter] = useState<string[] | null>(null);
  const [cropValues, setCropValues] = useState<string[]>([])
  const [cropFilter, setCropFilter] = useState<string[] | null>(null);
  
  const [aiFilter, setAiFilter] = useState<string | null>(null);
  const [progressFilter, setProgressFilter] = useState<string[] | null>(null);
  const [progressValues, setProgressValues] = useState<string[]>([])
  const [uniqueNumberFilter, setUniqueNumberFilter] = useState<string | null>(null);

  const [regNoFilter, setRegNoFilter] = useState<string | null>(null);
  const { t } = useTranslation();
  const titleId = useId('title');
  const { instance } = useMsal();

  useEffect(() => {

    const missing = tradeNameFilter?.filter(value => tradeNameValues?.indexOf(value) === -1);
    if (missing && missing?.length > 0) {
      let cvalues = tradeNameValues ? tradeNameValues : []
      cvalues = cvalues.concat(missing)
      setTradeNameValues(cvalues);
    }
  }, [tradeNameFilter, tradeNameValues]);

  useEffect(() => {
    const missing = countryStateFilter?.filter(value => countryStateValues?.indexOf(value) === -1);
    if (missing && missing?.length > 0) {
      let cvalues = countryStateValues ? countryStateValues : []
      cvalues = cvalues.concat(missing)
      setCountryStateValues(cvalues);
    }
  }, [countryStateFilter, countryStateValues]);

  useEffect(() => {
    function _buildOverviewColumns(): IColumn[] {

      const columns: IColumn[] = [
        
        { key: "CTradeName", name: t("TradeName"), fieldName: "TradeName", minWidth: 150, maxWidth: 200, isResizable: true, showSortIconWhenUnsorted: true },
        { key: "CountryState", name: t("CountryState"), fieldName: "CountryState", minWidth: 150, isResizable: true, showSortIconWhenUnsorted: true },
        { key: "Status", name: t("Status"), fieldName: "Status", minWidth: 150, isResizable: true, showSortIconWhenUnsorted: true },
        { key: "ProductCategory", name: t("ProductCategory"), fieldName: "ProductCategory", minWidth: 150, isResizable: true, showSortIconWhenUnsorted: true },
        { key: "TypeOfSubmission", name: t("TypeOfSubmission"), fieldName: "TypeOfSubmission", minWidth: 150, isResizable: true, showSortIconWhenUnsorted: true },
        { key: "Registrationdate", name: t("Registrationdate"), fieldName: "Registrationdate", minWidth: 150, isResizable: true, showSortIconWhenUnsorted: true },
        { key: "CertificateValidTill", name: t("CertificateValidTill"), fieldName: "CertificateValidTill", minWidth: 150, isResizable: true, showSortIconWhenUnsorted: true },
        { key: "ActiveIngredient", name: t("ActiveIngredient"), fieldName: "ActiveIngredient", minWidth: 150, isResizable: true, showSortIconWhenUnsorted: true },
        { key: "Progress", name: t("Progress"), fieldName: "Progress", minWidth: 150, isResizable: true, showSortIconWhenUnsorted: true },
        { key: "RegNo", name: t("RegNo"), fieldName: "RegNo", minWidth: 150, isResizable: true, showSortIconWhenUnsorted: true },
        { key: "UniqueNumber", name: t("UniqueNumber"), fieldName: "UniqueNumber", minWidth: 150, maxWidth: 200, isResizable: true, showSortIconWhenUnsorted: true },

      ]

      columns.forEach((column: IColumn) => {
        if (column.name) {
          column.showSortIconWhenUnsorted = true;
        }
      });

      return columns;
    }
    setColumns(_buildOverviewColumns());
  }, [t]);

  useEffect(() => {
    console.log("recalculate Filter");
    let filterdata = data.slice();
    if (tradeNameFilter && tradeNameFilter.length > 0)
      filterdata = filterdata.filter(f =>
        f.TradeName &&
        tradeNameFilter.filter(filter => f.TradeName?.toLowerCase().indexOf(filter.toLowerCase()) !== -1).length > 0
      );
    if (countryStateFilter && countryStateFilter.length > 0)
      filterdata = filterdata.filter(f => f.CountryState &&
        countryStateFilter.filter(filter => f.CountryState?.toLowerCase().indexOf(filter.toLowerCase()) !== -1).length > 0
      );
    if (statusFilter)
      filterdata = filterdata.filter(f => f.Status === statusFilter);
    if (productCategoryFilter)
      filterdata = filterdata.filter(f => f.ProductCategory === productCategoryFilter);
    if (typeOfSubmissionFilter)
      filterdata = filterdata.filter(f => f.TypeOfSubmission === typeOfSubmissionFilter);
    if (regNoFilter)
      filterdata = filterdata.filter(f => f.RegNo && f.RegNo?.toLowerCase().indexOf(regNoFilter.toLowerCase()) !== -1);
     if(cropFilter && cropFilter.length > 0) {
      filterdata = filterdata.filter(f => f.Crop &&
        cropFilter.filter(filter => f.Crop?.toLowerCase().indexOf(filter.toLowerCase()) !== -1).length > 0
      );
     }
     if(targetFilter && targetFilter.length > 0) {
      filterdata = filterdata.filter(f => f.Target &&
        targetFilter.filter(filter => f.Target?.toLowerCase().indexOf(filter.toLowerCase()) !== -1).length > 0
      );
     }
     if(progressFilter && progressFilter.length > 0) {
      filterdata = filterdata.filter(f => f.Progress &&
        progressFilter.filter(filter => f.Progress?.toLowerCase().indexOf(filter.toLowerCase()) !== -1).length > 0
      );
     }
     if(aiFilter) {
      filterdata = filterdata.filter(f => f.ActiveIngredient && f.ActiveIngredient?.toLowerCase().indexOf(aiFilter.toLowerCase()) !== -1);
     }
     if(uniqueNumberFilter) {
      filterdata = filterdata.filter(f => f.UniqueNumber && f.UniqueNumber?.toLowerCase().indexOf(uniqueNumberFilter.toLowerCase()) !== -1);
     }
    setViewData(filterdata);
  }
    , [tradeNameFilter, countryStateFilter, statusFilter, productCategoryFilter, regNoFilter, typeOfSubmissionFilter, targetFilter, cropFilter, progressFilter, data, aiFilter, uniqueNumberFilter]);

  useEffect(() => {
    (async () => {
      const dataservice: IDataService = new DataService();
      setLoading(true);
      const data = await dataservice.getListItems();
      setData(data);
      setTradeNameValues(data.map(obj => obj.TradeName).filter((value, index, self) => {
        return self.findIndex(item => value.toLowerCase() === item.toLowerCase()) === index;
      }).sort());
      setCountryStateValues(data.map(obj => obj.CountryState).filter((value, index, self) => self.indexOf(value) === index).sort());
      setStatusValues(data.map(obj => obj.Status).filter((value, index, self) => self.indexOf(value) === index).sort());
      setProductCategoryValues(data.map(obj => obj.ProductCategory).filter((value, index, self) => self.indexOf(value) === index).sort());
      setTypeOfSubmissionValues(data.map(obj => obj.TypeOfSubmission).filter((value, index, self) => self.indexOf(value) === index).sort());
      setProgressValues(data.map(obj => obj.Progress).filter((value, index, self) => self.indexOf(value) === index).sort());
      setTargetValues(data.map(obj => obj.Target).filter((value, index, self) => self.indexOf(value) === index).sort());
      setCropValues(data.map(obj => obj.Crop).filter((value, index, self) => self.indexOf(value) === index).sort());
      setLoading(false);
      console.log("Data loaded");
    })();
  }, []);
  const exportFile = useCallback(() => {
    const ws = utils.json_to_sheet(viewData);
    const wb = utils.book_new();
    utils.book_append_sheet(wb, ws, "Data");
    writeFile(wb, `RegulatoryExport.xlsx`);
  }, [viewData]);

  return (<>
    {loading && (<div className='center'><Spinner size={SpinnerSize.large} /></div>)}
    {!loading && (<>
      <div className='filterArea'>
        <Stack horizontal wrap style={{ width: '100%' }} >
          <FilterComboBox name={t("TradeName")} values={tradeNameValues} selected={tradeNameFilter} onValueSelected={(x) => setTradeNameFilter(x)} />
          <FilterComboBox name={t("CountryState")} values={countryStateValues} selected={countryStateFilter} onValueSelected={(x) => setCountryStateFilter(x)} />
          <FilterDropdown name={t("Status")} values={statusValues} selected={statusFilter} onValueSelected={(x) => setStatusFilter(x)} />

          <FilterDropdown name={t("ProductCategory")} values={productCategoryFilterValues} selected={productCategoryFilter} onValueSelected={(x) => setProductCategoryFilter(x)} />
          <FilterDropdown name={t("TypeOfSubmission")} values={typeOfSubmissionValues} selected={typeOfSubmissionFilter} onValueSelected={(x) => setTypeOfSubmissionFilter(x)} />
          <FilterText name={t("ActiveIngredient")} value={aiFilter} onValueChanged={(x) => setAiFilter(x)} />
          <FilterComboBox name={t("Progress")} values={progressValues} selected={progressFilter} onValueSelected={(x) => setProgressFilter(x)} />
          <FilterText name={t("RegNo")} value={regNoFilter} onValueChanged={(x) => setRegNoFilter(x)} />
          <FilterComboBox name={t("Target")} values={targetValues} selected={targetFilter} onValueSelected={(x) => setTargetFilter(x)} />
          <FilterComboBox name={t("Crop")} values={cropValues} selected={cropFilter} onValueSelected={(x) => setCropFilter(x)} />
          <FilterText name={t("UniqueNumber")} value={uniqueNumberFilter} onValueChanged={(x) => setUniqueNumberFilter(x)} />
          
         

        </Stack>

      </div>
      <div className='actionbar'>
      <DefaultButton text={t("ResetFilter") || undefined} onClick={() => {
                setTradeNameFilter(null);
                setCountryStateFilter(null);
                setTypeOfSubmissionFilter(null);
                setStatusFilter(null);
                setProductCategoryFilter(null);
                setProgressFilter(null);
                setAiFilter(null);
                setRegNoFilter(null);
                setCropFilter(null);
                setTargetFilter(null);
                setUniqueNumberFilter(null);
              }} 
              disabled={
                !tradeNameFilter &&
                !countryStateFilter&& 
                !statusFilter &&
                !productCategoryFilter &&
                !regNoFilter &&
                !typeOfSubmissionFilter&&
                !targetFilter&&
                !cropFilter &&
                !progressFilter &&
                !aiFilter &&
                !uniqueNumberFilter
              } 
              />
        <IconButton iconProps={{ iconName: 'ExcelLogoInverse' }} label={t("Excel") || undefined} onClick={() => {
          exportFile();
        }} />
      </div>
      <ListView viewItems={viewData} columns={columns}
        openItemClicked={(id) => {
          console.log("got event");
          setDetailViewID(id);
          setDetailItem(data.filter(f => f.ID === id)[0]);
        }} />

    </>)}
    {detailViewID && detailItem && (<>
      <Modal
        titleAriaId={titleId}
        isOpen={detailViewID > 0}
        onDismiss={() => {
          setDetailViewID(null);
          setDetailItem(null);
        }}
        isBlocking={false}
        containerClassName={contentStyles.container}
        dragOptions={undefined}

      >
        <div className={contentStyles.header}>
          <h2 className={contentStyles.heading} id={titleId}>
            {detailItem?.UniqueNumber}-{detailItem?.TradeName}
          </h2>
          <IconButton
            styles={iconButtonStyles}
            iconProps={cancelIcon}
            ariaLabel="Close popup modal"
            onClick={() => setDetailViewID(null)}
          />
        </div>
        <div className={contentStyles.body}>
          <DetailView Id={detailViewID} instance={instance} overviewItem={detailItem} />

        </div>
      </Modal>
    </>)}
  </>);

}

const cancelIcon: IIconProps = { iconName: 'Cancel' };
const theme = getTheme();
const contentStyles = mergeStyleSets({
  container: {
    display: 'flex',
    flexFlow: 'column nowrap',
    alignItems: 'stretch',
  },
  header: [
    theme.fonts.xLargePlus,
    {
      flex: '1 1 auto',
      borderTop: `4px solid ${theme.palette.themePrimary}`,
      color: theme.palette.neutralPrimary,
      display: 'flex',
      alignItems: 'center',
      fontWeight: FontWeights.semibold,
      padding: '12px 12px 14px 24px',
    },
  ],
  heading: {
    color: theme.palette.neutralPrimary,
    fontWeight: FontWeights.semibold,
    fontSize: 'inherit',
    margin: '0',
  },
  body: {
    minWidth: '60em',
    flex: '4 4 auto',
    padding: '0 24px 24px 24px',
    overflowY: 'hidden',
    selectors: {
      p: { margin: '14px 0' },
      'p:first-child': { marginTop: 0 },
      'p:last-child': { marginBottom: 0 },
    },
  },
});

const iconButtonStyles: Partial<IButtonStyles> = {
  root: {
    color: theme.palette.neutralPrimary,
    marginLeft: 'auto',
    marginTop: '4px',
    marginRight: '2px',
  },
  rootHovered: {
    color: theme.palette.neutralDark,
  },
};
