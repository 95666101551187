import * as React from 'react';
import { IFileItem } from '../interfaces/IFileItem';
import { DataService } from '../services/DataService';
import { IDataService } from '../services/IDataService';
//import styles from './ViewFileArea.module.scss';

export interface IViewFileAreaProps {
    Data?: IFileItem[];
    GroupName: string;
}

export interface IViewFileAreaState {
    downloading: string[]
}

export default class ViewFileArea extends React.Component<IViewFileAreaProps, IViewFileAreaState> {
    private dataservice: IDataService;
    constructor(props: IViewFileAreaProps) {
        super(props);
        this.state={
            downloading:[]
        }

        this.dataservice = new DataService();
    }
    public render(): React.ReactElement<IViewFileAreaProps> {
        const { GroupName, Data } = this.props;
        const { downloading } = this.state;
        return (
            <div>
                {Data && Data.length > 0 &&
                    (
                        <div>
                            <h3>{GroupName}</h3>
                            <div className='content'>
                                {Data.map((d, i) => {
                                    return (<div className={downloading.filter(p => p === d.FileId).length > 0 ? 'downloading' : 'downloadable'} key={"dlid" + i} onClick={() => { this.startDownload(d.FileId, d.Name) }}>{d.Name}</div>);
                                })}
                            </div>
                        </div>
                    )

                }
            </div>
        );
    }

    private startDownload(fileId: string, name: string): void {
        const downloading = this.state.downloading.slice();
        if(downloading.filter(x=>x ===fileId).length>0){
            console.log('download is running')
            return;
        }
        downloading.push(fileId);
        this.setState({ downloading: downloading },
            () => {


                this.dataservice.getFile(fileId).then(blob => {
                    const url = window.URL.createObjectURL(
                        new Blob([blob]),
                    );
                    const link = document.createElement('a');
                    link.href = url;
                    link.setAttribute(
                        'download',
                        name,
                    );
                    document.body.appendChild(link);
                    link.click();
                    link.parentNode?.removeChild(link);
                   let downloaddone = this.state.downloading?.filter(x=>x !==fileId).slice();
                   
                    this.setState({ downloading: downloaddone });
                });
            })
    }
}
