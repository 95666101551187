import { Spinner, SpinnerSize } from '@fluentui/react';
import * as React from 'react';

import { FileArea, IFileItem } from '../interfaces/IFileItem';
import { DataService } from '../services/DataService';
import { IDataService } from '../services/IDataService';
import ViewFileArea from './ViewFileArea';
//import styles from './ViewFiles.module.scss';

export interface IViewFilesProps {
    itemId:number;
    t: any;
}

export interface IViewFilesState {
    loading:boolean;
    data?:IFileItem[];
}

export default class ViewFiles extends React.Component<IViewFilesProps, IViewFilesState> {
    private dataservice: IDataService;
    constructor(props: IViewFilesProps) {
        super(props);

        this.state = {
            loading: false
        };
        this.dataservice = new DataService();
    }
componentDidMount(): void {
    this.loadData();
}
componentDidUpdate(prevProps: Readonly<IViewFilesProps>, prevState: Readonly<IViewFilesState>, snapshot?: any): void {
    if(this.props.itemId !== prevProps.itemId){
        this.loadData();
    }
}

private loadData(){
    this.setState({loading:true,data:undefined},()=>{
        this.dataservice.getFiles(this.props.itemId).then(data=>{
            this.setState({loading:false,data:data});
        }).catch((err)=>{
            this.setState({loading:false,data:undefined});
        })
    })
}

  public render(): React.ReactElement<IViewFilesProps> {
    const {loading,data} = this.state;
    const {t} = this.props;
    if(loading){
        return (<>
        <Spinner size={SpinnerSize.large} />
        </>)
    }
    const labels=data?.filter(f=>f.Area === FileArea.Labels);
    const sds=data?.filter(f=>f.Area === FileArea.SDS);
    return (
      <div>
        <ViewFileArea Data={labels} GroupName={t("Labels")} />
        <ViewFileArea Data={sds} GroupName={t("SDS")} />
      </div>
    );
  }
}
