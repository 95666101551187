import { Spinner, SpinnerSize, Stack } from '@fluentui/react';
import * as React from 'react';
import { IListItem } from '../interfaces/IListItem';
import { IListItemDetail } from '../interfaces/IListItemDetails';
import { DataService } from '../services/DataService';
import { IDataService } from '../services/IDataService';
import ViewProperty from './ViewProperty';
import { withTranslation } from 'react-i18next';
import ViewPersonProperty from './ViewPersonProperty';
import ViewGAPTable from './ViewGapTable';
import { IPublicClientApplication } from "@azure/msal-browser";
import ViewFiles from './ViewFiles';

export interface IDetailViewProps {
    Id: number;
    overviewItem: IListItem;
    t: any;
    i18n: any;
    instance: IPublicClientApplication;
}

export interface IDetailViewState {
    details?: IListItemDetail;
    loading: boolean;
}

class DetailView extends React.Component<IDetailViewProps, IDetailViewState> {
    private dataservice: IDataService;
    constructor(props: IDetailViewProps) {
        super(props);

        this.state = {
            loading: false
        };
        this.dataservice = new DataService();
    }

    public componentDidMount(): void {
        this.loadData(this.props.Id);
    }

    public componentDidUpdate(prevProps: Readonly<IDetailViewProps>): void {
        if (this.props.Id !== prevProps.Id) {
            this.loadData(this.props.Id);
        }
    }

    public render(): React.ReactElement<IDetailViewProps> {
        const { loading, details } = this.state;
        const { overviewItem, t } = this.props
        return (
            <div>
                <Stack horizontal >
                    <Stack>
                        <Stack className='content' horizontal wrap style={{ width: '100%'}}  >
                            <ViewProperty text={t("TradeName")} value={overviewItem.TradeName} />
                            <ViewProperty text={t("CountryState")} value={overviewItem.CountryState} />

                            <ViewProperty text={t("Status")} value={overviewItem.Status} />
                            <ViewProperty text={t("RegNo")} value={overviewItem.RegNo} />
                            <ViewProperty text={t("ProductCategory")} value={overviewItem.ProductCategory} />
                            <ViewProperty text={t("TypeOfSubmission")} value={overviewItem.TypeOfSubmission} />
                            <ViewProperty text={t("Registrationdate")} value={overviewItem.Registrationdate?.toLocaleDateString("de-DE")} />
                            <ViewProperty text={t("CertificateValidTill")} value={overviewItem.CertificateValidTill?.toLocaleDateString("de-DE")} />
                            <ViewProperty text={t("Progress")} value={overviewItem.Progress} />
                            <ViewProperty text={t("ActiveIngredient")} value={overviewItem.ActiveIngredient} />
                        </Stack>
                        {loading && (<Spinner size={SpinnerSize.large} />)}
                        {!loading && details && (
                            <Stack className='content' horizontal wrap style={{ width: '100%' }} >
                                <ViewProperty text={t("RegisteredPackaging")} value={details.RegisteredPackaging} />
                                <ViewProperty text={t("FormulationType")} value={details.FormulationType} />
                                <ViewProperty text={t("ProjectType")} value={details.ProjectType} />
                                <ViewProperty text={t("ProductCode")} value={details.ProductCode} />
                                <ViewProperty text={t("Distributor")} value={details.Distributor} />
                                <ViewProperty text={t("RegisteredShelfLife")} value={details.RegisteredShelfLife} />
                                <ViewProperty text={t("PlannedEffectiveDateOfSubmission")} value={details?.PlannedEffectiveDateOfSubmission?.toLocaleDateString("de-DE")} />
                                <ViewProperty text={t("RealisticApprovalDate")} value={details.RealisticApprovalDate?.toLocaleDateString("de-DE")} />
                                <ViewProperty text={t("GracePeriod")} value={details?.GracePeriod} />
                                <ViewPersonProperty text={t("ResponsiblePerson")} value={details?.ResponsiblePerson} />
                                <ViewProperty text={t("Entity")} value={details?.Entity} />
                                <ViewProperty text={t("BestApprovalDate")} value={details.BestApprovalDate?.toLocaleDateString("de-DE")} />
                                <ViewProperty text={t("RegHolder")} value={details.RegHolder} />
                                
                         </Stack>)}
                    </Stack>
                    <Stack style={{minWidth:"400px",
                    border:"1px solid gray",paddingLeft:"0.5em" }}>

                        <ViewFiles itemId={this.props.Id} t={t} />
                    </Stack>
                </Stack>
                <Stack className='multiline'>
                    {!loading && details && (<ViewGAPTable values={details.GAPTable} noData={t("NoGapData")} />)}
                </Stack>

            </div>
        );
    }
    private loadData(id: number) {
        this.setState({ loading: true }, () => {
            this.dataservice.getListItem(id).then((item) => {
                this.setState({ loading: false, details: item });
            }).catch(err => { this.setState({ loading: false }) });
        });
    }

}

export default withTranslation()(DetailView);