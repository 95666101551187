import React from 'react';
import ReactDOM from 'react-dom';
import { App } from './App';
import { createTheme, mergeStyles, Theme, ThemeProvider } from '@fluentui/react';
import reportWebVitals from './reportWebVitals';

import { PublicClientApplication } from "@azure/msal-browser";
import { msalConfig } from "./authConfig";

import { initializeIcons } from '@fluentui/react/lib/Icons';
initializeIcons(/* optional base url */);
// Inject some global styles
mergeStyles({
  ':global(body,html,#root)': {
    margin: 0,
    padding: 0,
    height: '100vh',
  },
});

export const msalInstance = new PublicClientApplication(msalConfig);

const appTheme: Theme = createTheme({
  palette: {
    themePrimary: 'red'
  }
});

ReactDOM.render(
<React.StrictMode>
<ThemeProvider theme={appTheme}>
<App instance={msalInstance} />
</ThemeProvider>
</React.StrictMode>
  , document.getElementById('root'));

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
