import { Dropdown, IconButton, Stack } from '@fluentui/react';
import * as React from 'react';
//import styles from './FilterDropdown.module.scss';

export interface IFilterDropdownProps {
    name:string;
    values:string[];
    selected:string|null;
    onValueSelected:(selected:string|null)=>void
}

export interface IFilterDropdownState {}

export default class FilterDropdown extends React.Component<IFilterDropdownProps, IFilterDropdownState> {
  public render(): React.ReactElement<IFilterDropdownProps> {
    const {name,values,selected,onValueSelected} =this.props;
    return (
        <Stack horizontal >
        <span className='name'>{name}</span>
        <Stack horizontal   >
          <Dropdown style={{width: '15em',textAlign:'left'}} selectedKey={selected} options={values.map(x => { return ({ key: x, text: x }); })} onChange={(e, o) => { onValueSelected(o ? "" + o.key : null); }} />
          <div style={{width: '2em'}}>
          {selected && <IconButton iconProps={{ iconName: "Cancel" }} onClick={() => { onValueSelected(null); }} />}
          </div>
        </Stack>
      </Stack>
    );
  }
}
