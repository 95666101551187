import {  Stack } from '@fluentui/react';
import * as React from 'react';
import { IEntityPerson } from '../interfaces/IListItemDetails';
//import styles from './ViewProperty.module.scss';

export interface IViewPersonPropertyProps {
  text: string;
  value?: IEntityPerson[];
}

export interface IViewPersonPropertyState { }

export default class ViewPersonProperty extends React.Component<IViewPersonPropertyProps, IViewPersonPropertyState> {

  constructor(props: IViewPersonPropertyProps) {
    super(props);

    this.state = {

    };
  }

  public render(): React.ReactElement<IViewPersonPropertyProps> {
    const { text, value } = this.props;
    console.log(value);
    const users = value?.map(x => {
      return ({
        imageInitials: x?.Initials,
        text: x?.Displayname,
        secondaryText: x?.Email,
      });
    }
    );
    return (
      <Stack horizontal >
        <span className='proplabel'>{text}:</span>
        <Stack style={{ width: '16em' }}  >
          {value &&
          users?.map(u=>{
            return (
         <a
         className='downloadable'
         rel="noreferrer"
         target={'_blank'}
         href={`mailto:${u.secondaryText}`}>
          <span>{u.text}</span>
         </a>
            
            );
          }) 
          }
        </Stack>


      </Stack>
    );
  }
}
